import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MobileUndo } from '@core/models/mobile-undo.model';
import { Entity } from '@core/enums/entity.enum';

@Injectable({
  providedIn: 'root',
})
export class MobileUndoService {
  public undoAction: BehaviorSubject<MobileUndo> = new BehaviorSubject({ visible: false });
  public undoAction$: Observable<MobileUndo> = this.undoAction.asObservable();

  private undoFinished$ = new Subject<any>();
  private undoVisible$ = new BehaviorSubject<boolean>(false);

  public show(entity: Entity): void {
    this.undoAction.next({ visible: true, entity });
  }

  public reset(): void {
    this.undoAction.next({ visible: false });
  }

  public getUndoFinished(): Observable<boolean> {
    return this.undoFinished$.asObservable();
  }

  public setUndoFinished(finished: boolean): void {
    this.undoFinished$.next(finished);
    this.reset();
  }

  public getUndoVisible(): Observable<boolean> {
    return this.undoVisible$.asObservable();
  }

  public setUndoVisible(visible: boolean): void {
    this.undoVisible$.next(visible);
  }
}
