import { Observation } from '@core/models/observation.model';
import { MapItem } from '@capturum/ui/api';

export class ScanBlock {
  public static readonly type = '[Observation] Block Scanned';

  constructor(public block: MapItem) {}
}

export class PatchObservation {
  public static readonly type = '[Observation] Observation patched';

  constructor(public observation: Partial<Observation>) {}
}

export class AddObservation {
  public static readonly type = '[Observation] Add Observation';

  constructor(public observation: Partial<Observation>) {}
}

export class ResetObservation {
  public static readonly type = '[Observation] Reset Observation';
}

export class ResetCurrentObservation {
  public static readonly type = '[Observation] Reset current Observation';
}

export class AddCurrentObservation {
  public static readonly type = '[Observation] Add current Observation';
}

export class RemoveObservation {
  public static readonly type = '[Observation] Remove Observation';

  constructor(public observationId: string) {}
}
